import React, { useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { Navigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const AuthGuard = ({ children, redirectPath = '/' }) => {
  const { currentUser, loading, setCurrentUser } = useContext(AuthContext);

  useEffect(() => {
    console.log(currentUser.emailVerified)
    const checkAuth = async () => {
      console.log('...')
      if (currentUser && !currentUser.emailVerified) {
        try {
          const auth = getAuth();
          await signOut(auth);
          setCurrentUser(null);
          MySwal.fire({
            title: "Oops",
            text: `Para ingresar, confirma tu correo electronico`,
            icon: "error",
          });
        } catch (error) {
          console.error('Error signing out:', error);
        }
      }
    };
    checkAuth();
  }, [currentUser, setCurrentUser]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!currentUser || !currentUser.emailVerified) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};

export default AuthGuard;