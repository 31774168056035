import React from 'react';

const RepliesSection = ({ replies, commentUsers }) => {

    return (
        <>
            <div className="replies-container">
                {replies.map((subcomment) => (
                    <div key={subcomment.id} className="comment-container subcomment">
                        <div className="content2" >
                            <div className="meta">
                                <span style={{display:'flex',flexDirection:'row',gap:'5px'}}>                                    
                                    <p id="username">{commentUsers[subcomment.uid]?.username || 'Desconocido'}</p>
                                    <p id="user-title">{commentUsers[subcomment.uid]?.profession || 'Desconocido'}</p></span>
                                <p className="comment-fecha">{subcomment.createdAt?.toDate().toLocaleString()}</p>

                            </div>
                        </div>

                        <div className="content">


                            <div className="post-text">
                                {subcomment.text ? (
                                    <div dangerouslySetInnerHTML={{ __html: subcomment.text }} />
                                ) : (
                                    '...'
                                )}
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </>
    );
};

export default RepliesSection;
