import React from "react";
import "./Messages.css";
import "aos/dist/aos.css";
import TicketEditor from "./TicketEditor";

const Messages = () => {



  return (
   <>
 <section className="ticket-editor-container" id="main" >

   <TicketEditor/>
   </section>
   </>
  );
};

export default Messages;

