import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import {
  CodeIcon,
  TicketIcon,
  ProfileIcon,
  HomeIcon,
  TicketsIcon,
  LogoutIcon,
  LogoSvg,
  ExecuteIcon,
} from "../Utils/Svgs";
import Profile from "../../Pages/Profile/Profile";
import "./Navbar.css";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SelectedUserProfile from "../../Pages/Profile/SelectedUserProfile";
import CodeEditor from "../CodeRunner/CodeEditor";

const MySwal = withReactContent(Swal);

function Navbar() {
  const { currentUser } = useContext(AuthContext);
  const [showProfile, setShowProfile] = useState(false);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Check if the user is new (email not verified)
  const isNewUser = currentUser && !currentUser.emailVerified;

  useEffect(() => {
    // Only show the profile if the user is new and doesn't have a username
    if (currentUser && isNewUser && !currentUser.username) {
      setShowProfile(true);
    }
  }, [isNewUser, currentUser]);

  // Initialize position state for profile and code editor (start at x: 0, y: 0)
  const [profilePos, profileApi] = useSpring(() => ({ x: 0, y: 0 }));
  const [codeEditorPos, codeEditorApi] = useSpring(() => ({ x: 0, y: 0 }));
  const [fromAgendaPos, fromAgendaApi] = useSpring(() => ({ x: 0, y: 0 }));

  // Handle dragging of elements (Profile, Code Editor, Selected User Profile)
  const bindProfilePos = useDrag((params) => {
    profileApi.start({ x: params.offset[0], y: params.offset[1] });
  });

  const bindCodeEditorPos = useDrag((params) => {
    codeEditorApi.start({ x: params.offset[0], y: params.offset[1] });
  });

  const bindFromAgendaPos = useDrag((params) => {
    fromAgendaApi.start({ x: params.offset[0], y: params.offset[1] });
  });

  // Toggle the profile view with a smooth fade-out transition
  const toggleProfile = () => {
    if (!currentUser) {
      showAuthMessage();
      return;
    }

    if (showProfile) {
      setFadeOut(true);
      setTimeout(() => {
        setShowProfile(false);
        setFadeOut(false);
      }, 500);
    } else {
      setShowProfile(true);
    }
  };

  // Toggle the code editor view
  const toggleCodeEditor = () => {
    setShowCodeEditor((prev) => !prev);
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error during logout:", error);
      alert("Error during logout");
    }
  };

  // Show authentication-related messages using SweetAlert
  const showAuthMessage = () => {
    MySwal.fire({
      title: "Oops",
      text: "Para continuar, debes iniciar sesión",
      icon: "error",
    });
  };

  // Handle the selected user from the agenda
  const handleSelectAgendaUser = (user) => {
    setSelectedUser(user);
    // Reset the position of SelectedUserProfile
    fromAgendaApi.start({ x: 0, y: 0 });
  };

  const btnState = currentUser ? "icon-box" : "disabled";

  return (
    <nav className="navbar">
      <div className="logo">
        <LogoSvg />
      </div>
      <div className="inner-nav">
        <div className="menu-container">
          <Link to="/" className="icon-box">
            <HomeIcon />
            <p>Home</p>
          </Link>
          <div
            className={btnState}
            onClick={toggleProfile}
            style={{ cursor: currentUser ? "pointer" : "not-allowed" }}
          >
            <ProfileIcon />
            <p>Perfil</p>
          </div>

          {showProfile && (
            <div
              className={`profile-container ${fadeOut ? "fade-out" : "show"}`}
              style={{ position: "fixed", zIndex: 1000 }}
            >
              <animated.div {...bindProfilePos()} style={profilePos}>
                <Profile
                  onClose={toggleProfile}
                  isNew={isNewUser}
                  handleSelectAgendaUser={handleSelectAgendaUser}
                />
              </animated.div>
            </div>
          )}

          {/* Conditional Menu Items based on Authentication */}
          {!currentUser ? (
            <>
              <div onClick={showAuthMessage} className={btnState}>
                <TicketIcon />
                <p>Mis Tickets</p>
              </div>
              <div onClick={showAuthMessage} className={btnState}>
                <CodeIcon />
                <p>Crear Ticket</p>
              </div>
              <div onClick={showAuthMessage} className={btnState}>
                <TicketsIcon />
                <p>Tickets</p>
              </div>
            </>
          ) : (
            <>
              <Link to="/myTickets" className={btnState}>
                <TicketIcon />
                <p>Mis Tickets</p>
              </Link>
              <div className={btnState} onClick={toggleCodeEditor}>
                <ExecuteIcon />
                <p>Ejecutar</p>
              </div>
              <Link to="/messages" className={btnState}>
                <CodeIcon />
                <p>Crear Ticket</p>
              </Link>
              <Link to="/posts" className={btnState}>
                <TicketsIcon />
                <p>Tickets</p>
              </Link>
            </>
          )}

          {currentUser && (
            <Link to="/" onClick={handleLogout} className="icon-box">
              <LogoutIcon />
              <p>Logout</p>
            </Link>
          )}
        </div>
      </div>

      {/* Code Editor Modal */}
      {showCodeEditor && (
        <animated.div
          {...bindCodeEditorPos()}
          style={{
            ...codeEditorPos,
            zIndex: 9999,
            background: "linear-gradient(323deg, #101723, #102b42e3)",
            top: "15%",
            left: "-40%",
            padding: "1vw",
            borderRadius:'10px'
          }}
          className="code-editor card"
        >
          <CodeEditor onCloseEditor={toggleCodeEditor} />
        </animated.div>
      )}

      {/* Selected User Profile Modal */}
      {selectedUser && (
        <div
          className={`selected-user-container ${fadeOut ? "fade-out" : "show"}`}
          style={{ position: "fixed", zIndex: 2000,top:'15%',left:'20%' }} // Ensure higher z-index
        >
          <animated.div {...bindFromAgendaPos()} style={fromAgendaPos}>
            <SelectedUserProfile
              onClose={() => setSelectedUser(null)}
              selectedUser={selectedUser}
            />
          </animated.div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
