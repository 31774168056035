import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import "./controlBlock.css";
import { login, register } from "./UserAuth";
import { getAuth } from "firebase/auth";
import { EyeIcon, ClosedEyeIcon } from "../Utils/Svgs";
import PassRecoverModal from "../../component/RegisterModal/PassRecoverModal";
import LoadingPage from "../Utils/LoadingPage";

const MySwal = withReactContent(Swal);

const AccountBlock = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const [activeForm, setActiveForm] = useState("login");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [registerEmail, setregisterEmail] = useState("");
  const [registerPassword, setregisterPassword] = useState("");
  const [registerPassword2, setregisterPassword2] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSwitch = (formName) => {
    setActiveForm(formName);
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    if (!loginEmail || !loginPassword) {
      MySwal.fire({
        title: "Error",
        text: "Por favor, complete todos los campos.",
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    setLoading(true);

    try {
      await login(loginEmail, loginPassword);
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error);
      MySwal.fire({
        title: "Error de inicio de sesión",
        text: 'Intenta nuevamente',
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();
  
    let swalResponse = "";
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(.{8,})$/;
  
    if (!registerEmail || !registerPassword || !registerPassword2) {
      swalResponse = "Campos vacíos";
    } else if (registerPassword !== registerPassword2) {
      swalResponse = "Las contraseñas no coinciden";
    } else if (!passwordRegex.test(registerPassword)) {
      swalResponse = "La contraseña debe tener al menos 8 caracteres, una mayúscula y un número o carácter especial";
    }
  
    if (swalResponse) {
      MySwal.fire({
        title: "Oops",
        text: swalResponse,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
      return;
    }
  
    setLoading(true);
  
    try {
      await register(registerEmail, registerPassword);
      setLoading(false);
      MySwal.fire({
        title: "Registro exitoso",
        text: `Bienvenido, se ha enviado un correo de confimación al:${registerEmail}.`,
        icon: "success",
        confirmButtonText: "Entendido",
      }).then(() => {
        const auth = getAuth();
        auth.signOut();
        navigate("/"); 
      });
    } catch (error) {
      setLoading(false);
      MySwal.fire({
        title: "Error en el registro",
        text: error.message,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <section className="forms-section">
      {loading && (

        <div className="wrapper-container">

        <div className="loadingWrapper">
        <span className="spin-loader">CODE</span>
        <span className="spin-loader2">TREK</span>
        </div>
        </div>
      )}
      <div className="btn-container">

        <div id="login-btn" onClick={() => handleSwitch("login")}> <span></span> <span></span> <span></span> <span></span>Ingresa </div>


        <div className="register-btn" aria-label="Open login modal" onClick={() => handleSwitch("signup")} > <span></span> <span></span> <span></span> <span></span> Registrate </div>

      </div>
      <div className="forms">
        <div
          style={{marginTop:'5vh'}}
          className={`form-wrapper ${activeForm === "login" ? "is-active" : ""
            }`}
        >
          <form className="form form-login" onSubmit={handleLogin}>
            <fieldset>
              <legend>Ingresa tu email y contraseña para ingresar</legend>
              <div className="input-block">
                <label htmlFor="login-email">E-mail</label>
                <input
                  type="email"
                  id="login-email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-block pass-block">
                <label htmlFor="login-password">Contraseña:</label>
                <input
                  id="login-password"
                  type={isPasswordVisible ? "text" : "password"}
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle"
                >
                  {isPasswordVisible ? (
                    <ClosedEyeIcon />
                  ) : (
                    <EyeIcon />
                  )}
                </button>
              </div>
              <button
                type="submit"
                className="custom-btn btn-4"
                disabled={loading}
                style={{position:'relative',left:'0',margin:'0 auto',marginBottom:'2vh'}}
              >
                {" "}
                {loading ? "Cargando..." : "Entrar"}{" "}
              </button>
              <span className="recover-pass-si-por-que-no" onClick={() => setShowModal(true)} >recuperarContraseña</span>

            </fieldset>
          </form>
        </div>
        <div
          className={`form-wrapper ${activeForm === "signup" ? "is-active" : ""
            }`}
        >
          <form className="form form-signup" onSubmit={handleRegister}>
            <fieldset>
              <legend>
                Ingresa tu email y contraseña para registrarte
              </legend>
              <div className="input-block" style={{ width: '100%', paddingBottom: '0', marginBottom: '0' }}>
                <label htmlFor="signup-email">E-mail</label>
                <input
                  id="signup-email"
                  type="email"
                  value={registerEmail}
                  onChange={(e) => setregisterEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-block pass-block" style={{ width: '50%' }}>
                <label htmlFor="signup-password">Contraseña:</label>
                <input
                  id="signup-password"
                  type={isPasswordVisible ? "text" : "password"}
                  value={registerPassword}
                  onChange={(e) => setregisterPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle"
                >
                  {isPasswordVisible ? (
                    <ClosedEyeIcon />
                  ) : (
                    <EyeIcon />

                  )}
                </button>

              </div>
              <div className="input-block pass-block" style={{ width: '50%' }}>
                <label htmlFor="signup-password-confirm">
                  Confirmar Contraseña:
                </label>
                <input
                  id="signup-password-confirm"
                  type={isPasswordVisible ? "text" : "password"}
                  value={registerPassword2}
                  onChange={(e) => setregisterPassword2(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="password-toggle"
                >
                  {isPasswordVisible ? (
                    <ClosedEyeIcon />
                  ) : (
                    <EyeIcon />

                  )}
                </button>
              </div>
              <button
                type="submit"
                className="custom-btn btn-4"
                disabled={loading}
                style={{position:'relative',left:'0',margin:'0 auto',marginBottom:'2vh'}}
              >
                {" "}
                {loading ? "Cargando..." : <span>Registrarse</span>}{" "}
              </button>
              <span className="recover-pass-si-por-que-no" onClick={() => setShowModal(true)} >recuperarContraseña</span>
            </fieldset>
          </form>
        </div>
      </div>
      <PassRecoverModal show={showModal} handleClose={handleModalClose} />
    </section>
  );
};

export default AccountBlock;
