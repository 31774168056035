import React, { useEffect, useState, useMemo, useCallback } from 'react';
import "./CommentSection.css";
import Messages from "../Comments/CommentEditor";
import CommentRating from './CommentRating';
import RepliesSection from './RepliesSection';
import { MessageIcon, TagsIcon } from '../../component/Utils/Svgs';
import { fetchTicketById, fetchCommentsByTicketId, fetchReplies, fetchUserData } from '../../component/Utils/FirebaseOperations';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
const MemoizedRepliesSection = React.memo(RepliesSection);

const CommentSection = ({ ticketId, onExpandClick }) => {
  const [comments, setComments] = useState([]);
  const [commentUsers, setCommentUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeMessages, setActiveMessages] = useState({});
  const [cachedUserData, setCachedUserData] = useState({});
  const [showReplies, setShowReplies] = useState({});

  const toggleShowReplies = useCallback((commentId) => {
    setShowReplies((prevShowReplies) => ({
      ...prevShowReplies,
      [commentId]: !prevShowReplies[commentId],
    }));
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const fetchedComments = await fetchComments(ticketId);

      const commentsWithReplies = await Promise.all(
        fetchedComments.map(async (comment) => {
          const replies = await fetchReplies(comment.id);
          const repliesUserData = await Promise.all(replies.map((reply) => fetchUserData(reply.uid, cachedUserData, setCachedUserData)));
          const repliesUsersMap = replies.reduce((acc, reply, index) => {
            acc[reply.uid] = repliesUserData[index];
            return acc;
          }, {});

          setCommentUsers((prevCommentUsers) => ({
            ...prevCommentUsers,
            ...repliesUsersMap,
          }));

          return {
            ...comment,
            replies,
          };
        })
      );

      const usersData = await Promise.all(fetchedComments.map((comment) => fetchUserData(comment.uid, cachedUserData, setCachedUserData)));
      const usersMap = fetchedComments.reduce((acc, comment, index) => {
        acc[comment.uid] = usersData[index];
        return acc;
      }, {});

      setCommentUsers((prevCommentUsers) => ({
        ...prevCommentUsers,
        ...usersMap,
      }));

      setComments(commentsWithReplies);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, cachedUserData, setCachedUserData]);

  const handleCommentAdded = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleToggleMessages = useCallback((commentId) => {
    setActiveMessages((prevActiveMessages) => ({
      ...prevActiveMessages,
      [commentId]: !prevActiveMessages[commentId]
    }));
  }, []);

  useEffect(() => {
    if (ticketId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [ticketId, fetchData]);

  const fetchComments = useCallback(async (ticketId) => {
    const ticketData = await fetchTicketById(ticketId);
    if (!ticketData) {
      return [];
    }

    const comments = await fetchCommentsByTicketId(ticketId);
    return comments.map((comment) => ({
      id: comment.id,
      ...comment,
      replies: [],
      likeCount: comment.likeCount || 0,
    }));
  }, []);

  const commentComponents = useMemo(() => {
    if (loading) {
      return <p>Cargando...</p>;
    }

    if (comments.length === 0) {
      return <p>Vacío</p>;
    }

    return comments.map((comment) => (
      <div key={comment.id} className="container row comment-container">
        <div className="row-comment">
          <div className="content user-detail" style={{ flex: 0 }}>
            <div className="meta">

              <p id="username">
                {commentUsers[comment.uid]?.username || "Desconocido"}
              </p>
              <p id="user-title">
                {commentUsers[comment.uid]?.profession || "Desconocido"}
              </p>
            </div>
          </div>

          <div className="content comment-text">

            <div className="post-text">
              {comment.text ? (
                <div dangerouslySetInnerHTML={{ __html: comment.text }} />
              ) : (
                "..."
              )}
              <div className='fecha-comm'>
                {formatDistanceToNow(new Date(comment.createdAt?.toDate()), { addSuffix: true, locale: es })}
              </div>
            </div>

            <div className="reply-container">
              <button className="expand-code" onClick={() => toggleShowReplies(comment.id)}>
                <span className="btn-text">
                  {`${comment.replies.length}`}
                  <MessageIcon />
                </span>
              </button>
              <CommentRating commentId={comment.id} likeCount={comment.likeCount} />
              <button className="expand-code" onClick={() => handleToggleMessages(comment.id)}>
                <span className="btn-text">Responder</span>
              </button>
              <button onClick={() => onExpandClick(comment)} className="expand-code">
                Ver código
                <TagsIcon />
              </button>
            </div>
          </div>
        </div>
        {activeMessages[comment.id] && (
          <Messages
            codeContent={`// ${comment.code}`}
            codeLanguage={comment.language}
            ticketId={comment.id}
            commentType="subcomment"
            onCommentAdded={handleCommentAdded}
          />
        )}
        {showReplies[comment.id] && (
          <MemoizedRepliesSection replies={comment.replies} commentUsers={commentUsers} />
        )}
      </div>
    ));
  }, [loading, comments, commentUsers, activeMessages, showReplies, toggleShowReplies, handleToggleMessages, onExpandClick, handleCommentAdded]);

  return <>{commentComponents}</>;
};

export default CommentSection;