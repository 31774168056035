import React, { useContext, useState, useEffect } from "react";
import "./ChatComponent.css";
import TextEditor from "../TextEditor/TextEditor";
import {
  sendMessage,
  getCurrentUser,
  fetchMessages,
  fetchAgenda,
} from "../../component/Utils/FirebaseOperations";
import { ProfileIcon, ReplyBtn } from "../Utils/Svgs";
import { AuthContext } from "../Auth/AuthContext";

const ChatComponent = ({ selectedUser, fromProfile = null, handleSelectAgendaUser }) => {
  const { userData } = useContext(AuthContext);

  const [editorContent, setEditorContent] = useState("");
  const [messages, setMessages] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const userId = getCurrentUser()?.uid;

  function getOppositeElement(usersObject, userId) {
    const otherElement =
      usersObject.sender?.uid === userId
        ? usersObject.receiver
        : usersObject.receiver?.uid === userId
        ? usersObject.sender
        : null;
    return otherElement;
  }

  useEffect(() => {
    if (!userId) return;

    fetchAgenda(userId, setAgenda);

    if (selectedUser) {
      const unsubscribeMessages = fetchMessages(selectedUser?.uid, userId, setMessages);
      return () => {
        unsubscribeMessages();
      };
    }
  }, [userId, selectedUser]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const usersData = {
    sender: userData,
    receiver: selectedUser,
  };

  const handleSendMessage = async () => {
    if (editorContent.trim() === "") return; // Prevent sending empty messages
    await sendMessage(userId, selectedUser?.uid, editorContent, usersData);
    setEditorContent("");
  };

  return (
    <div className="row chat-container" style={{ height: "inherit" }}>
      <div className="col-4" style={{ margin: "0 auto" }}>
        <div className="user-contact-container">
          {fromProfile &&
            agenda.map((item) => {
              const oppositeUser = getOppositeElement(item.usersData, userId);
              // Ensure oppositeUser and its properties exist
              if (!oppositeUser || !oppositeUser.username) return null;

              return (
                <div
                  key={item.id || `${oppositeUser.uid}-${item.someOtherUniqueProp}`} // Ensure uniqueness
                  className="user-contact-box"
                  onClick={() => handleSelectAgendaUser(oppositeUser)}
                >
                  <ProfileIcon />
                  <span className="chat-title">{oppositeUser.username}</span>
                </div>
              );
            })}
        </div>
      </div>

      <div className={fromProfile ? "col-8" : "col-10"} style={{ margin: "0 auto", height: "inherit" }}>
        <div className="chat-box">
          <div className="message-box">
            <div className="chat-content">
              {messages.map((msg) => (
                <div
                  key={msg.id || `${msg.senderId}-${msg.timestamp}`} // Ensure uniqueness
                  className={`msg-item ${msg.senderId === userId ? "msg-sended" : "msg-reply"}`}
                >
                  <span
                    className="note-body"
                    dangerouslySetInnerHTML={{ __html: msg.content }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {!fromProfile && (
          <div className="editor">
            <TextEditor content={editorContent} onEditorChange={handleEditorChange} />
            <div className="reply-message-btn" onClick={handleSendMessage}>
              <ReplyBtn />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatComponent;
