import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchTickets, fetchCommentCount } from "../../component/Utils/FirebaseOperations";
import './HomeBlocks.css';
import CardContainer from "./CardContainer";
import PrismCode from "../../component/TextEditor/PrismCode";
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';

const HomeBlock = ({ showFive = false }) => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [commentCount, setCommentCount] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const fetchedTickets = await fetchTickets([]);
                
                const counts = await Promise.all(fetchedTickets.map(async (ticket) => {
                    const count = await fetchCommentCount(ticket.id);
                    return { id: ticket.id, count };
                }));

                setTickets(fetchedTickets);
                setCommentCount(counts.reduce((acc, { id, count }) => {
                    acc[id] = count;
                    return acc;
                }, {}));

            } catch (error) {
                console.error("Error fetching tickets:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [showFive]);

    const calculateActivityScore = (ticket) => {
        const commentScore = commentCount[ticket.id] || 0;
        const viewScore = ticket.viewCount || 0;
        return commentScore + viewScore;
    };

    const sortedTickets = tickets
        .sort((a, b) => calculateActivityScore(b) - calculateActivityScore(a))
        .slice(0, 5);

    if (loading) return (
        <div className="wrapper-container">
            <div className="loadingWrapper">
                <span className="spin-loader">CODE</span>
                <span className="spin-loader2">TREK</span>
            </div>
        </div>
    );

    if (error) return <p>Error loading tickets</p>;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="row">
            <div className="col-md-6">
                <h2 className="homeblocktitle">&lt;Destacados/&gt;</h2>
                <Slider {...settings}>
                    {sortedTickets.map((ticket) => (
                        <div key={ticket.id} onClick={() => navigate(`/ticket/${ticket.id}`)}>
                            <CardContainer>
                                <div className="display-meta">{'//'} {formatDistanceToNow(new Date(ticket.createdAt?.toDate()), { addSuffix: true, locale: es })}</div>
                                <h3>{ticket.title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: ticket.text }} />
                                <PrismCode code={ticket.code || ""} language={ticket.language || "javascript"} />
                                <div className="info-details">
                                    <p>Comentarios: {commentCount[ticket.id] ?? 'Cargando...'}</p>
                                    <p>Visitas: {ticket?.viewCount ?? 0}</p>
                                </div>
                            </CardContainer>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="col-md-5 offset-md-1 side-cat-container">
                <h2 className="homeblocktitle">&lt;Recientes/&gt;</h2>
                {tickets.map((ticket) => (
                    <div key={ticket.id} className="display-container side-cat" onClick={() => navigate(`/ticket/${ticket.id}`)}>
                        <CardContainer>
                            <h3>{ticket.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: ticket.text }} />
                            <p>Comentarios: {commentCount[ticket.id] ?? 'Cargando...'}</p>
                            <p>Visitas: {ticket?.viewCount ?? 0}</p>
                        </CardContainer>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeBlock;
