import React from 'react';
import './Footer.css';
import { LogoSvg, LinkedinIcon, GithubIcon, DiscordIcon } from '../Utils/Svgs'

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-2">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 mb-50">
                            <div className="footer-widget">
                                <div className="footer-cta pt-3 pb-2" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div className="footer-logo">
                                        <div>
                                            <LogoSvg />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-4 col-md-4 mb-30">
                                            <div className="single-cta">
                                                <LinkedinIcon />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-4 mb-30">
                                            <div className="single-cta">
                                                <GithubIcon />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-4 mb-30">
                                            <div className="single-cta">
                                                <DiscordIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
                                <div className="copyright-text">
                                    <p>Copyright © 2024, Todos los derechos reservados <span style={{ color: '#00aaff', letterSpacing: '1px', fontWeight: '600' }}>CODETREK</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
