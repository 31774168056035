import React from 'react'; 

const UserTags = ({ tags }) => {
  return (
    <div className="user-tags-container">
      {tags && tags.length > 0 ? (
        <div className="tags-list">
          {tags.map((tag, index) => (
            <span key={index} className="code-tag" style={{ margin: '5px' }}>
              {tag}
            </span>
          ))}
        </div>
      ) : (
        <p>No hay tags disponibles para este ticket.</p> 
      )}
    </div>
  );
};

export default UserTags;
