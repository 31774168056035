import React, { useState, useEffect } from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import PostsPage from "../PostsPage/PostsPage";
import "./MyTickets.css";

const MyTickets = () => {
    return (
        <>
         <PostsPage isMyTickets={true}/>  
        </>
    );
};

export default MyTickets;
