import React, { useEffect, useState, useContext, useRef } from "react";
import Typed from "typed.js";
import Login from "../../component/LoginModal/Login";
import Register from "../../component/RegisterModal/Register";
import { AuthContext } from "../../component/Auth/AuthContext";
import UserControl from "../../component/UserControl/AccountBlock";
import { LogoSvg } from '../../component/Utils/Svgs';
import PostsPage from "../PostsPage/PostsPage";
import HomeBlocks from "./HomeBlocks";
import AOS from "aos";
import './Home.css';
import '../../component/LoginModal/Login.css';

AOS.init();

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenReg, setIsModalOpenReg] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(!currentUser);
  const typedElementRef = useRef(null); 

  useEffect(() => {
    if (!currentUser && typedElementRef.current) {
      const options = {
        strings: ["React", "Ionic", "C++", "Laravel", ".NET", "PHP", "104 101 108 112"],
        typeSpeed: 50,
        backSpeed: 50,
        loop: true,
      };

      const typed = new Typed(typedElementRef.current, options);

      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => {
        typed.destroy();
        clearTimeout(loadingTimeout);
      };
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("modal")) {
      closeModalLogin();
      closeModalRegister();
    }
  };

  const closeModalLogin = () => setIsModalOpen(false);
  const closeModalRegister = () => setIsModalOpenReg(false);

  const openModalLogin = () => setIsModalOpen(true);
  const openModalRegister = () => setIsModalOpenReg(true);

  if (isLoading) {
    return (
      <div className="wrapper-container">
        <div className="loadingWrapper">
          <span className="spin-loader">CODE</span>
          <span className="spin-loader2">TREK</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section id="main" className="d-flex flex-column home" style={{ overflow: 'visible', width: '90%' }}>
        <div className='row' style={{ zIndex: '0' }}>
          {!currentUser ? (
            <>
              <div className="col-md-5 col-lg-5 col-12 logo-code" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "10vw", position: 'relative' }}>
                <LogoSvg />
                <p style={{ width: '60%', margin: '0 auto' }}>
                  <span ref={typedElementRef} className="typed"></span>
                </p>
                <UserControl />
              </div>
              <div className="col-md-7 col-lg-7 col-12 latest">
                <div className="box-featured">
                  <PostsPage showFive={true} loggedIn={false} mainPage={true} />
                </div>
              </div>
            </>
          ) : (
            <HomeBlocks showFive={5} />
          )}
        </div>
      </section>

      {isModalOpen && (
        <div className="modal" onClick={handleOverlayClick}>
          <div>
            <span className="close" onClick={closeModalLogin}>&times;</span>
            <Login openModalRegister={openModalRegister} closeModalLogin={closeModalLogin} />
          </div>
        </div>
      )}

      {isModalOpenReg && (
        <div className="modal" onClick={handleOverlayClick}>
          <div>
            <span className="close" onClick={closeModalRegister}>&times;</span>
            <Register openModalLogin={openModalLogin} closeModalRegister={closeModalRegister} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
